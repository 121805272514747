import React from "react"
import styled from "styled-components"
import { useArticles } from "src/hooks"
import { AnimatePresence, motion } from "framer-motion"
import parse from "html-react-parser"
import { TitleContrast, BodyText } from "src/atoms/typography.js"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 768px) {
    padding: 8rem 0;
  }
`

const Copyrights = () => {
  const articles = useArticles({ articleCategory: 9066 })
  const { list } = articles
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Wrapper>
          <TitleContrast>{list[0]?.Title}</TitleContrast>
          {list[0]?.Content && <BodyText>{parse(list[0].Content)}</BodyText>}
        </Wrapper>
      </motion.div>
    </AnimatePresence>
  )
}

export default Copyrights
