import styled, { css } from "styled-components"

export const TitleBig = styled.h3`
  font-size: 7.5rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  color: #1e1e1e;
`

export const TitleContrast = styled.h4`
  background-color: #1e1e1e;
  color: #e9d1bb;
  font-family: "Montserrat", sans-serif;
  font-size: 1.875rem;
  font-weight: 700;
  max-width: max-content;
  padding: 0.5rem 2rem;
  margin-bottom: 5rem;

  @media (max-width: 768px) {
    ${({ notGrid }) =>
      notGrid &&
      css`
        margin-left: 5%;
      `};
  }
`

export const Subtitle = styled.p`
  font-family: "Playfair Display", serif;
  font-weight: 700;
  font-size: 3.75rem;
  font-style: italic;
  line-height: 3.75rem;
`

export const BodyText = styled.p`
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.8px;
  line-height: 1.625rem;
`

export const BodyTextWrap = styled.div`
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.8px;
  line-height: 1.625rem;
  & > p {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.8px;
    line-height: 1.625rem;
  }
`

export const MenuText = styled.p`
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 3.2px;
`

export const SmlText = styled.p`
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  font-style: italic;
  /* line-height: 1.625rem; */
`

export const BodyTextObj = {
  "font-family": '"Montserrat", sans-serif',
  "font-weight": "500",
  "font-size": "1rem",
  "letter-spacing": "0.8px",
  "line-height": "1.625rem",
}
