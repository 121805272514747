import React, { useEffect } from "react"
import Copyrights from "src/views/Copyrights"

const Page = () => {
  return (
    <Copyrights/>
  )
}

export default Page
